
<template>

  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        
        <h1 class="text-center navbar-brand pt-0 pb-0 mb-0" v-if="cursoActualNombre">{{cursoActualNombre}}</h1>
        
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-button class="nav-link" @click="actualizar" variant="link" >Actualizar</b-button>
          </b-navbar-nav>

      </div>
    </b-navbar>
    
    <div v-if="registro" class="container">
      
      <div v-if="registro.length>0">
        <b-row class="text-center mb-3 mt-3 sm-12 col-md">
          <b-col class="col-xs-12 col-md"><strong>ID</strong></b-col>
          <b-col class="text-left col-xs-12"><strong>Tema</strong></b-col>
          <b-col class="col-xs-12"><strong>Estado</strong></b-col>
          <b-col cols="1" class="text-left col-xs-12"><strong>Nota</strong></b-col>
          <b-col class="col-xs-12"><strong>Docente</strong></b-col>
        </b-row>
       
        <b-row class="text-center mb-3 mt-3 sm-12 col-md" v-for="(fila,index) in registro" :key="index">
          <b-col class="col-xs-12 col-md">{{index+1}}</b-col>
          <b-col class="text-left col-xs-12">{{fila.nombre}}</b-col>
          <b-col v-if="fila.progreso">
            <div v-if="fila.progreso.estado=='superado'">
              <icon style="color:'green'" class="check-icon" scale="0.6" name="check"></icon>  
            </div>
            <div v-else>{{fila.progreso.estado}}</div>
          </b-col>
          <b-col  v-else>pendiente</b-col>
          <!--   <b-col v-if="fila.progreso">{{fila.nota}}</b-col> -->
          <b-col cols="1" class="text-left col-xs-12" v-if="fila.progreso">{{fila.progreso.nota}}</b-col>
          <b-col cols="1" v-else></b-col> 
          <b-col v-if="fila.progreso" style="font-size: 15px;">{{docent(fila.progreso)}}<br><p style="font-size: 9px;">{{fila.progreso.salon != null ? ubicacion(fila.progreso.salon, fila.progreso.clase_fecha ):""}}</p></b-col>
          <b-col v-else></b-col> 
        </b-row>

      </div>
      <div v-else>
        Aún no tiene registros.
      </div>

    </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import utils from '@/modules/utils';
  import Icon from 'vue-awesome/components/Icon';
  import 'vue-awesome/icons/check';

    // console.log(fila)
  export default {
    name: 'RegistroAcademico',
    components: {
      Icon
    },
    data () {
      return {
        
      }
    },
    mounted () {
      if(!this.cursoActual){
        this.fetchCursoActual()
      }
      if(this.registro.length==0){
        this.actualizar()  
      }
      
    },
    methods:{
      ...mapActions({
        fetchRegistroAcademicoActual: 'academico/fetchRegistroAcademicoActual',
        fetchCursoActual: 'academico/fetchCursoActual',
      }),
      ...mapMutations({
        
      }),
      actualizar(){
        this.fetchCursoActual()
        this.fetchRegistroAcademicoActual()
      },
      ubicacion(salon, fecha){
        var fec = ""
        var ho = "";
        var sede = ""
        if(fecha != null && fecha != ""){
          fec = fecha.split('T')[0]
          ho = new Date(fecha);
          fecha = fec.split("-")[2] + "/"+ fec.split("-")[1] + "/" + fec.split("-")[0] + " " + ho.getHours()+":"+ho.getMinutes()+":"+ho.getSeconds();
        }else{fecha=""}
        if(salon!=null)
          sede = JSON.parse(salon).sede
        else
          sede = ""
        return  sede +" "+ fecha;
      },
      docent(progreso){
        if(progreso && progreso.docente){
          if(progreso.docente != null){
            var docente = JSON.parse(progreso.docente)
            return docente.nombre
          }else{return "";}
        }
        else{return "";}
      },
      
    },
    computed: {
      ...mapState({
        registro: state => state.academico.registro_academico_actual, 
        cursoActual: state => state.academico.cursoActual,
      }),
      ...mapGetters({
        
      }),
      cursoActualNombre(){
        if(this.cursoActual){
          return this.cursoActual.nombre
        }
        return false
      }
    }
  }
</script>

<style scope>

	.programaciones_wrapper .tabla{
      
	}
</style>