<template>
    <div>
        <b-navbar toggleable="col-md-12" type="dark" variant="info" class="pt-0 pb-0" >
            <div class="container-fluid bg-info">
                <h1 class="text-center navbar-brand pt-0 pb-0 mb-0">Notificaciones</h1>
            </div>
        </b-navbar><br>
        <div class="col-md-12 m-auto">
            <div class="row col-md-12" style="text-align: center !important; display: flex;justify-content: center;align-items: center;">
                <b-card v-for="(banner,index) in banners" :key="index"
                    img-top
                    style="max-width: 40rem;max-height: 30rem;padding: 10px;margin: 10px;"
                    class="mb-6 md-6">

                    <b-card-text>
                      <a v-if="banner.destination_url" :href="banner.destination_url" target="_blank">
                        <b-img fluid-grow  :src="banner.url" style="width: auto !important;height: auto !important;"></b-img>
                      </a>

                      <b-img v-else fluid-grow  :src="banner.url" style="width: auto !important;height: auto !important;"></b-img>
                    </b-card-text>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';

  export default {
    name: 'banners',
    data () {
      return {
        principal:""
      }
    },
    mounted () {
        this.fetchBanners()
    },
    methods:{
      ...mapActions({
        fetchBanners: 'banner/fetchBanners',
      }),
        
    },
    computed:{
      ...mapState({
        banners: state => state.banner.banners
      }),
      
    }
  }
</script>
