<template>
   <div id="app">
    <navigation></navigation>
    <div v-if="processing">
      <BlockUI :message="blockMessage"></BlockUI>
    </div>
    <div v-if="error">
      <BlockUI :message="error"></BlockUI>
    </div>
    <div v-if="warning">
      <BlockUI :message="warning"></BlockUI>
    </div>
    <b-modal  no-close-on-backdrop no-close-on-esc hide-header hide-footer ok-only 
    v-model="updateExists"  id="modalupgrading" >
      <div class="text-center">
        <h5>Encontramos una nueva versión de la aplicación, es necesario actualizar.</h5>
        <b-button variant="success" @click="refreshApp">
          Actualizar ahora
        </b-button>
      </div>
    </b-modal>

    <!--MODAL PARA BANNERS PUBLICIDAD-->

    <b-modal hide-footer hide-header ok-only v-model="bannerDialogShowed" id="modalBanners" title=""> 
      <div v-if="ultimo_banner">
        <a v-if="ultimo_banner.destination_url" :href="ultimo_banner.destination_url" target="_blank">
          <b-img fluid-grow  :src="ultimo_banner.url"></b-img>
        </a>

        <b-img v-else fluid-grow  :src="ultimo_banner.url"></b-img>

        <b-btn variant="danger" @click="bannerDialogShowed=false">Cerrar</b-btn>
      </div>
    </b-modal>

    <!--FIN  DE MODAL PUBLICIDAD-->
    <div v-for="mensaje in mensajes">
      <b-alert show dismissible :variant="mensaje.variant" class="mb-0">
        <div v-html="mensaje.html"></div>
      </b-alert>
    </div>

    <router-view></router-view>
    <footer class="bd-footer text-muted pt-5 mt-5 text-center">
      <div class="container-fluid pt-5 mt-5">
        
        <img class="logo-footer" src="@/assets/blocks.jpeg">
        <p>Programador de clases para American School Way</p>
        <p>(Estudiante) Versión Actual {{ packageVersion }}
        </p>

        <p><a target="_blank" href="https://www.americanschoolway.edu.co/danos-tu-opinion">Danos tu opinión y PQRSF</a></p>
        <!--p><a href="http://www.disarrollo.com" target="_blank">Diseñado y construido por Disarrollo Software SaS</a></p-->
        <span>2018</span>

        <div>
          <div style="display: inline-block;">
            <div style="background-color: yellow;width: 48px;height: 6px"></div>
            <div style="background-color: blue;width: 48px;height: 3px"></div>
            <div style="background-color: red;width: 48px;height: 3px"></div>
          </div>
        </div>
      </div>

      <template v-if="isDebug">  
        <div>Base Url:{{baseUrl}}</div>
        <div>nodeEnv:{{nodeEnv}}</div>
        <div>appInstance: {{appInstance}}</div>
        <b-button @click="reload" variant="link">reload</b-button>
      </template>

      <div v-if="updateExists">
        Encontramos una actualizacion
        <b-button @click="refreshApp">
          Actualizar ahora
        </b-button>
      </div>

    </footer>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {mapState,mapActions, mapMutations} from 'vuex';
  import Navigation from '@/components/Navigation.vue';  
  import utils from '@/modules/utils';

  export default {
    components: {
        Navigation,
    },
    name: 'app',
    data () {
      return {
        blockMessage: 'Procesando...',
        registration: null,
        updateExists: false,
      }
    },
    mounted(){
      console.log("mounted:"+new Date())
      
      this.buscarClaseActual()

      if(this.logged){
        this.verificarAceptacionTerminos()
        this.verificarMensajes()

        this.fetchTipos() //Cachear
        this.fetchSedes() //Cachear

        this.fetchCursoActual() //Empaquetar
        this.fetchSedeActual() //Empaquetar

        this.fetchBanners()
        .then((result)=>{
          if(result && result.length>0){
            this.bannerShowDialog()
          }
        })
      }
      
      /* 
        En celulares no funciona si la app esta oculta
        Debe optimizarse enviando mensajes desde el servidor a la hora adecuada
        Pueden ser por Whatsapp, o sms
        Tambien puede optimizarse seteando un Timer a la hora exacta de la clase
        en vez de buscar cada minuto
      /*
      
      var intervalID = window.setInterval(()=>{
        this.buscarClaseActual()
      }, 60000);
      */

      try{
        //Se pone en bloque try porque genera error en Safari Mobile
        Vue.notification.requestPermission()  
      }catch(e){
        console.log("Error solicitando permisos de notificacion")
        console.log(e)
      }

      console.log("mounted finished")
      
    },
    created(){
      //Necesario para las actualizaciones de la aplicacion
      //Ejecuta el evento updateAvailable cuando se encuentre actualizaciones
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    },
    
    updated(){
      /*
      ESTE SE EJECUTA CADA VEZ QUE EL MODELO (DATA) SE ACTUALIZA
      POR ESO NO SE DEBE ACTUALIZAR DESDE EL MISMO METODO POR QUE ENTRARIA EN UN CICLO INFINITO
      */
    },
    
    methods: {
      ...mapActions({
        setWarning: 'setWarning',
        verificarAceptacionTerminos: 'academico/verificarAceptacionTerminos',
        verificarMensajes: 'auth/verificarMensajes',
        fetchCursoActual: 'academico/fetchCursoActual', //
        fetchTipos: 'academico/fetchTipos', //
        fetchSedeActual:'academico/fetchSedeActual', //
        fetchBanners: 'banner/fetchBanners', //
        fetchSedes: 'sedes/fetchLista',
      }),
      ...mapMutations({
        bannerShowDialog : 'banner/showDialog',
        bannerHideDialog : 'banner/hideDialog'
      }),

      
      buscarClaseActual(){
        let tiempoActual = new Date()
        tiempoActual.setSeconds(0)
        tiempoActual = new Date(tiempoActual.getTime()+60000)

        //console.log('buscando:'+tiempoActual.toString())
        //console.log('minute:'+tiempoActual.getMinutes())

        if(tiempoActual.getHours() > 5 && 
          (tiempoActual.getMinutes() == 0 || tiempoActual.getMinutes() == 30)){
        
          let claseProxima = this.clases.find((element)=>{
            let fecha_bloque = new Date(element.fecha_bloque)
            return element.estado == 'asignado' && tiempoActual.toString() == fecha_bloque.toString()
          })

          if(claseProxima && claseProxima.estado == 'asignado'){
              var options = {hour: 'numeric', minute: 'numeric' };
              let time = new Date(claseProxima.fecha_bloque)
              let texto = time.toLocaleString('es-CO',options)

              if(claseProxima.clase && claseProxima.clase.salon){
                texto += " en "+claseProxima.clase.salon.nombre  
              }
              this.notify(texto)
          }
        }
        
      },
      notify (value) {

        if(this.show_notification){
          this.$notification.show('Tienes clase!', {
            body: value,
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            badge: 'https://asw.extandar.com/img/icons/android-chrome-192x192.png',
            icon: 'https://asw.extandar.com/img/icons/android-chrome-192x192.png'
          }, {})
        }
        
      },
      
      reload(){
        window.location.reload(true);
      },
      updateAvailable(event) {
        this.registration = event.detail
        this.updateExists = true
      },
      refreshApp() {
        this.updateExists = false

        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        
        //Permite la actualizacion del service worker
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })

        //Recarga la aplicacion
        window.location.reload(true);
      }
      
    },
    computed: {

      ...mapState({
        clases: state => state.academico.clases, 
        isDebug: state => state.isDebug, 
        processing: state => state.processing,
        packageVersion: state => state.packageVersion,
        baseUrl: state => state.baseUrl,
        nodeEnv: state => state.nodeEnv,
        appInstance: state => state.appInstance,
        error: state => state.error,
        warning: state => state.warning,
        
        show_notification: state => state.show_notification,
        logged: state => state.auth.logged,
        mensajes: state => state.auth.mensajes,
        cursoActual: state => state.academico.cursoActual, //
        tipos: state => state.academico.tipos, //
        sedeActual: state => state.academico.sedeActual, //
        user: state => state.auth.user, //
        banners: state => state.banner.banners, //
        _bannerDialogShowed: state => state.banner.bannerDialogShowed, //
        sedes: state  => state.sedes.lista,
      }),
      
      ultimo_banner(){
        if(this.banners.length>0){
          return this.banners[0]
        }
        return null
      },
      bannerDialogShowed: {
        get () {
          return this._bannerDialogShowed
        },
        set(value){
          if(value){
            this.bannerShowDialog()
          }else{
            this.bannerHideDialog()
          }
        }
      }
    }

  }
</script>


<style>

.logo-footer{
  width: 300px;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.bg-red{
    background-color:#BC2336;
  }
.bg-blue{
    background-color:#0D4E8C !important;
}
hr {
  border: 1px solid #E33A2D !important;
  width: 100%;
}  
.bd-footer{background-color: #f7f7f7;font-size: 85%;}
.bd-footer p{
  margin:0px;
}

</style>
